import { Injectable } from "@angular/core";
import { Address } from "@cq/app/core/location/models/address";
import {
  Building,
  Location,
} from "@cq/app/policy/products/businessowners/models/location-building-models";
import { BehaviorSubject, map } from "rxjs";

const address1: Address = {
  line1: "1234 Main St",
  city: "Ames",
  state: "IA",
  zip: "50010",
  country: "US",
};

const address2: Address = {
  line1: "5678 Main St",
  city: "Ames",
  state: "IA",
  zip: "50014",
  country: "US",
};

const building1Loc1: Building = {
  id: "building:1",
  locationNum: 1,
  buildingNum: 1,
  addr: address1,
  buildingOrTIBLimit: "Building - $100,000",
  buildingClassification: "(59680) Florist (Retail)",
  hasError: false,
  isEligible: true,
};
const building2Loc1: Building = {
  id: "building:2",
  locationNum: 1,
  buildingNum: 2,
  addr: address2,
  buildingOrTIBLimit: "TIB - $100,000",
  bppLimit: "$100,000",
  buildingClassification: "(59680) Florist (Retail)",
  hasError: true,
  isEligible: true,
};
const building1Loc2: Building = {
  id: "building:3",
  locationNum: 2,
  buildingNum: 1,
  addr: address1,
  bppLimit: "$100,000",
  buildingClassification: "(59680) Florist (Retail)",
  hasError: false,
  isEligible: false,
};

let buildings: Building[] = [building1Loc1, building2Loc1, building1Loc2];

function groupBuildingsByLocation(buildings: Building[]): Location[] {
  const grouped = buildings.reduce(
    (group: { [key: number]: Building[] }, building) => {
      if (!group[building.locationNum]) {
        group[building.locationNum] = [];
      }
      group[building.locationNum].push(building);
      return group;
    },
    {},
  );

  return Object.entries(grouped).map(([key, buildings]) => {
    return {
      locationNumber: Number(key),
      buildings,
    };
  });
}

@Injectable({
  providedIn: "root",
})
export class BopBuildingService {
  buildings$ = new BehaviorSubject(buildings);
  locations$ = this.buildings$.pipe(map(groupBuildingsByLocation));

  delete(building: Building) {
    buildings = buildings.filter((value) => value !== building);
    this.buildings$.next(buildings);
  }
}
