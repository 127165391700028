import { EventEmitter, Injectable, OnDestroy, computed } from "@angular/core";
import { Router } from "@angular/router";
import { BUSINESSOWNERS } from "@cq/app/core/model/product";
import { EXACT, PARTIAL } from "@cq/app/core/routing/match-option";
import { RoutingService } from "@cq/app/core/routing/services/routing.service";
import { map } from "rxjs";
import { BopBuildingService } from "../building/services/bop-building.service";
import { PolicyTransactionStoreService } from "@cq/app/policy/services/policy-transaction-store.service";

@Injectable({
  providedIn: "root",
})
export class BopNavigationService implements OnDestroy {
  private destroy$ = new EventEmitter<void>();

  private transaction = computed(() => {
    return this.transactionStore
      .transactions()
      .find((transaction) => transaction.product === BUSINESSOWNERS);
  });
  private policyTransactionId = computed(() => this.transaction()?.id);
  hasBOP = computed(() => this.transaction() !== undefined);

  isBOPActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isBOPActive()),
  );
  isBOPInformationActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isBOPInformationActive()),
  );
  isBOPCoveragesActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isBOPCoveragesActive()),
  );
  // BOP Coverages is only available once there is a building associated to the BOP product
  isBOPCoveragesAvailable$ = this.building.locations$.pipe(
    map((locations) => locations.length > 0),
  );

  constructor(
    private readonly building: BopBuildingService,
    private readonly router: Router,
    private readonly routing: RoutingService,
    private readonly transactionStore: PolicyTransactionStoreService,
  ) {}

  isBOPActive() {
    return this.router.isActive(`${this.routePrefix}/businessowners`, PARTIAL);
  }

  isBOPInformationActive() {
    return this.router.isActive(
      `${this.routePrefix}/businessowners/information`,
      EXACT,
    );
  }

  isBOPCoveragesActive() {
    return this.router.isActive(
      `${this.routePrefix}/businessowners/coverages`,
      EXACT,
    );
  }

  navigateToBOPInformation() {
    return this.router.navigate([
      `${this.routePrefix}/businessowners/information`,
    ]);
  }

  navigateToBOPCoverages() {
    return this.router.navigate([
      `${this.routePrefix}/businessowners/coverages`,
    ]);
  }

  ngOnDestroy(): void {
    this.destroy$.emit();
  }

  private get routePrefix() {
    return `/upgrade/policy-transaction/${this.policyTransactionId()}`;
  }
}
